import {TUSerOrdersActions} from "./actions";
import {USER_ORDERS_ON_CLOSE, USER_ORDERS_ON_ERROR, USER_ORDERS_ON_MESSAGE, USER_ORDERS_ON_OPEN} from "./const";
import {TWsOrder} from "../../types/t-ws-order";

export type TUserOrdersState = {
    userOrders: TWsOrder[];
    isOpen: boolean;
    connectionError: Event | null;
};

export const initialState: TUserOrdersState = {
    userOrders: [],
    isOpen: false,
    connectionError: null,
};

export const reducer = ( state = initialState, action: TUSerOrdersActions)
    : TUserOrdersState => {
    switch (action.type) {
        case USER_ORDERS_ON_OPEN: {
            return {
                ...state,
                isOpen: true,
                connectionError: null,
            };
        }
        case USER_ORDERS_ON_MESSAGE: {
            return {
                ...state,
                userOrders: action.payload.data.orders,
            };
        }
        case USER_ORDERS_ON_ERROR: {
            return {
                ...state,
                connectionError: action.payload,
            };
        }
        case USER_ORDERS_ON_CLOSE: {
            return {
                ...state,
                isOpen: false,
            };
        }

        default: return state;
    }
};