

export const ALL_ORDERS_INIT: 'ALL_ORDERS_INIT' = 'ALL_ORDERS_INIT';
export const ALL_ORDERS_CLOSE: 'ALL_ORDERS_CLOSE' = 'ALL_ORDERS_CLOSE';

export const ALL_ORDERS_ON_OPEN: 'ALL_ORDERS_ON_OPEN' = 'ALL_ORDERS_ON_OPEN';
export const ALL_ORDERS_ON_ERROR: 'ALL_ORDERS_ON_ERROR' = 'ALL_ORDERS_ON_ERROR';
export const ALL_ORDERS_ON_CLOSE: 'ALL_ORDERS_ON_CLOSE' = 'ALL_ORDERS_ON_CLOSE';

export const ALL_ORDERS_ON_MESSAGE: 'ALL_ORDERS_ON_MESSAGE' = 'ALL_ORDERS_ON_MESSAGE';

