import React, {FC, useEffect} from "react";
import {closeAllOrdersConnection, initAllOrdersConnection} from "../../store/all-orders/actions";
import s from './feed.module.scss'
import {OrderList} from "../../components/feed-order/order-list/order-list";
import {OrdersInfo} from "../../components/feed-order/orders-info/orders-info";
import {allOrdersSelector} from "../../store/all-orders/selectors";
import {Preloader} from "../../components/preloader/preloader";
import {TWsOrder} from "../../types/t-ws-order";
import {useDispatch, useSelector} from "../../store/hooks.";

export const Feed: FC = () => {
    const dispatch = useDispatch();

    const orders: TWsOrder[] = useSelector(allOrdersSelector);
    const link = '/feed/';

    useEffect(() => {
        dispatch(initAllOrdersConnection());
        return () => dispatch(closeAllOrdersConnection())

    }, [dispatch]);

    return orders.length === 0
        ? <Preloader/>
        : (
            <div className={`pt-5 ${s.wrapper}`}>
                <h1 className="text text_type_main-large mb-5">Лента заказов</h1>
                <section className={s.section}>
                    <OrderList orders={orders} link={link}/>
                    <OrdersInfo  orders={orders}/>
                </section>
            </div>
        )
}