import s from "../burger-constructor.module.scss";
import {ConstructorElement, DragIcon} from "@ya.praktikum/react-developer-burger-ui-components";
import {
    deleteMainOrSauceFromConstructor,
    reorderMainAndSauceInConstructor
} from "../../../store/ingredients-in-constructor/actions";
import {FC, useRef} from "react";
import {useDrag, useDrop} from "react-dnd";
import {orderPendingSelector} from "../../../store/create-order/selectors";
import {Identifier} from "dnd-core";
import {TUpdatedIngredient} from "../../../types/t-ingredient";
import {useDispatch, useSelector} from "../../../store/hooks.";

const CONSTRUCTOR_ITEM = 'CONSTRUCTOR_ITEM';

type TBurgerConstructorItem = {
    ingredient: TUpdatedIngredient;
    idx: number


};

type TDragItem = {
    ingredient: TUpdatedIngredient,
    index: number
}

export const BurgerConstructorItem: FC<TBurgerConstructorItem> = ({ingredient, idx}) => {

    const dispatch = useDispatch();
    const isPending = useSelector(orderPendingSelector);

    const ref = useRef<HTMLDivElement>(null);


    const [{handlerId}, drop] = useDrop<TDragItem, unknown, { handlerId: Identifier | null }>({
        accept: CONSTRUCTOR_ITEM,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId()
            };
        },
        hover(item: TDragItem, monitor) {
            if (!ref.current) return;

            const dragIndex = item.index;
            const hoverIndex = idx;

            if (dragIndex === hoverIndex) return;

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const clientOffset = monitor.getClientOffset();

            if (!hoverBoundingRect || !clientOffset) return;

            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

            dispatch(reorderMainAndSauceInConstructor(dragIndex, hoverIndex));

            item.index = hoverIndex;
        }
    });


    const [, drag] = useDrag({
        type: CONSTRUCTOR_ITEM,
        item: () => {
            return {ingredient, idx};
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));


    return (
        <div
            ref={ref}
            data-handler-id={handlerId}
            key={ingredient._id}
            className={`${s.item} mt-4 mb-4`}>
            <DragIcon type='primary'/>

            <ConstructorElement
                text={ingredient.name}
                price={ingredient.price}
                thumbnail={ingredient.image}
                handleClose={() => !isPending && dispatch(deleteMainOrSauceFromConstructor(ingredient))}
            />
        </div>
    )
}

