import s from './tabs.module.scss';
import {Tab} from '@ya.praktikum/react-developer-burger-ui-components';
import {types} from "../../../types/types";
import {FC} from "react";

type TTabs = {
    activeTab: string
    setActiveTab: (tab: string) => void
}
export const Tabs: FC<TTabs> = ({activeTab, setActiveTab}) => {
    const scrollToTab = (tab: string) => {
        setActiveTab(tab);
        const element = document.getElementById(tab);
        if (element) element.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <div className={s.tabs}>
            {types.map(tab => {
                    return <Tab
                        key={tab.value}
                        value={tab.value}
                        onClick={scrollToTab}
                        active={activeTab === tab.value}>
                        {tab.text}
                    </Tab>
                }
            )}
        </div>
    );
};


