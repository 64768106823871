import React, {FC, useEffect} from "react";
import {closeUserOrdersConnection, initUserOrdersConnection} from "../../../store/user-orders/actions";
import {OrderList} from "../../../components/feed-order/order-list/order-list";
import {userOrdersSelector} from "../../../store/user-orders/selectors";
import {Preloader} from "../../../components/preloader/preloader";
import {TWsOrder} from "../../../types/t-ws-order";
import {useDispatch, useSelector} from "../../../store/hooks.";

export const OrderHistory: FC = () => {
    const dispatch = useDispatch();
    const orders: TWsOrder[] = useSelector(userOrdersSelector);
    orders?.sort((a, b) => (new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));

    const link = '/profile/orders/'

    useEffect(() => {
        dispatch(initUserOrdersConnection());
        return () => dispatch(closeUserOrdersConnection());
    }, [dispatch]);


    return (
        <div style={{position: 'relative'}}>
            {
                orders?.length === 0
                    ? <Preloader/>
                    : <OrderList orders={orders} link={link}/>
            }

        </div>
    )
}