import {Button, ConstructorElement, CurrencyIcon} from '@ya.praktikum/react-developer-burger-ui-components';
import s from './burger-constructor.module.scss';
import scroll from '../../common-styles/scroll.module.scss';
import {FC, useCallback, useEffect, useState} from "react";
import {OrderDetails} from "./order-details/order-details";
import {createModal} from "../../utils/create-modal";
import {useDrop} from "react-dnd";
import {
    getBunsInConstructor,
    getMainAndSauceInConstructor,
    getOrderButtonEnabled,
    getTotalBuns,
    getTotalMainAndSauce
} from "../../store/ingredients-in-constructor/selectors";
import {
    addBunToConstructor,
    addMainOrSauceToConstructor,
    resetConstructor
} from "../../store/ingredients-in-constructor/actions";
import {BurgerConstructorItem} from "./burger-constructor-item/burger-constructor-item";
import {closeOrderModal, createOrderAction} from "../../store/create-order/actions";
import {orderSelector} from "../../store/create-order/selectors";
import {authSelector} from "../../store/user-auth/selectors";
import {useNavigate} from "react-router-dom";
import {SmallPreloader} from "../preloader/preloader";
import {TIngredient, TUpdatedIngredient} from "../../types/t-ingredient";
import {useDispatch, useSelector} from "../../store/hooks.";

const BUN = 'bun';

export const BurgerConstructor: FC = () => {
    const dispatch = useDispatch();
    const isAuth = useSelector(authSelector);
    const navigate = useNavigate();

    const total = useSelector(getTotalBuns) + useSelector(getTotalMainAndSauce);
    const bunsData = useSelector(getBunsInConstructor);
    const mainAndSauceData = useSelector(getMainAndSauceInConstructor);

    const {isModalOpen, isPending, error} = useSelector(orderSelector);
    const isOrderButtonEnabled = useSelector(getOrderButtonEnabled);


    const [buttonValue, setButtonValue] = useState('Оформить заказ');

    useEffect(() => {
            if (isPending) {
                setButtonValue('Шлем сигнал на орбитальную станцию...');
            } else if (error) {
                 setButtonValue(error?.message ? error.message : 'что-то пошло не так');
            } else {
                setButtonValue('Оформить заказ');
            }
        },
        [isPending, error])


    const closeModal = useCallback(() => {
        dispatch(closeOrderModal());
        dispatch(resetConstructor());
    },[dispatch])

    const handleOrderCreate = () => {
        if (isAuth) {
            const orderList: string[] = [bunsData[0], ...mainAndSauceData, bunsData[1]].map(el => el._id);
            dispatch(createOrderAction(orderList));
        } else {
            return navigate('/login');
        }
    }

    const createModalCallback = useCallback(() => createModal(<OrderDetails/>, closeModal), [closeModal]);

    const [{isOver, canDrop}, dropTarget] = useDrop({
        accept: isPending ? 'fuck_you' : 'ingredient',
        drop(item: TIngredient) {
            item.type === BUN
                ? dispatch(addBunToConstructor(item))
                : dispatch(addMainOrSauceToConstructor(item))
        },
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    });

    const borderColor = canDrop || isOver ? '#762D76' : '#131316';
    const opacity = isPending ? .4 : 1


    return (
        <section className={`${s.wrapper} pt-15 mt-8`}>
            <div className={`${s.constructor_wrapper}`}>

                <div data-test='constructor-bun' ref={dropTarget} style={{border: '2px dashed #131316', borderRadius: '20px', borderColor, opacity}}>
                    {
                        bunsData.length !== 0
                            ?
                            <div className='pl-10'>
                                <ConstructorElement
                                    type='top'
                                    isLocked={true}
                                    text={`${bunsData[0].name} (верх)`}
                                    price={bunsData[0].price}
                                    thumbnail={bunsData[0].image}
                                />
                            </div>
                            :
                            <div
                                className={`${s.item_empty} ${s.item_empty_top} text text_type_main-default`}>
                                Выберите булку
                            </div>
                    }


                    <div data-test='constructor-main' className={`${s.items} ${scroll.scroll} pl-2 pr-2`}>

                        {
                            mainAndSauceData.length !== 0
                                ?
                                mainAndSauceData.map((ingredient: TUpdatedIngredient, idx: number) => (

                                    <BurgerConstructorItem key={ingredient.uiId} ingredient={ingredient} idx={idx}/>))
                                :
                                <div
                                    className={`${s.item_empty} ${s.item_empty_middle} text text_type_main-default `}>
                                    Выберите начинку
                                </div>

                        }

                    </div>

                    {
                        bunsData.length !== 0
                            ?
                            <div className='pl-10'>
                                <ConstructorElement
                                    type='bottom'
                                    isLocked={true}
                                    text={`${bunsData[0].name} (низ)`}
                                    price={bunsData[0].price}
                                    thumbnail={bunsData[0].image}
                                />
                            </div>
                            :
                            <div
                                className={`${s.item_empty} ${s.item_empty_bottom} text text_type_main-default`}>
                                Выберите булку
                            </div>
                    }
                </div>


                <div className={`${s.order} mt-10`}>
                    <div className={`${s.price} mr-10`}>
                        <span className='mr-2 text text_type_digits-medium'>{total}</span>
                        <CurrencyIcon type='primary'/>
                    </div>

                    <Button disabled={!isOrderButtonEnabled || isPending} type='primary' size='large' htmlType='submit'
                            style = {{position: 'relative'}}
                            onClick={handleOrderCreate}
                    >
                        {buttonValue}
                        {isPending && <SmallPreloader/>}
                    </Button>
                </div>
            </div>
            {
                isModalOpen &&
                createModalCallback()
            }
        </section>
    );
};

