import {FC} from "react";

export const OrderStatus: FC<{ status?: string }> = ({status= ""}) => {
    let color = "#F2F2F3";
    let text = "Создан";

    if (status === "pending") {
        text = "Готовится";

    } else if (status === "done") {
        text = "Выполнен";
        color = "#00CCCC";
    }

    return (
        <div className="text text_type_main-default pt-2"
             style={{color}}
        >{text}
        </div>
    );
};

