import s from './app-header.module.scss';
import {BurgerIcon, ListIcon, Logo, ProfileIcon,} from '@ya.praktikum/react-developer-burger-ui-components';
import {NavLink, useLocation} from "react-router-dom";
import React, {FC, useEffect, useState} from "react";
import {pendingSelector, userSelector} from "../../store/user-auth/selectors";
import {SmallPreloader} from "../preloader/preloader";
import {useSelector} from "../../store/hooks.";
import {TUserData} from "../../types/t-user-data";

export const AppHeader: FC = () => {
    const location = useLocation();
    const user: TUserData = useSelector(userSelector);
    const isPending = useSelector(pendingSelector);

    const [name, setName] = useState('');

    useEffect(() => {
        if (isPending) setName('')
        else if (user) setName(user.name || user.email)
        else setName('Личный кабинет')
    }, [isPending, user])


    return (
        <header className={s.header}>

            <div className={s.container}>
                <div className={s.user_options}>
                    <NavLink to='/' className={`${s.item} p-4`}>
                        {({isActive}) =>
                            <>
                                <BurgerIcon type={isActive ? 'primary' : 'secondary'}/>
                                <p className={`text text_type_main-default ml-2 ${!isActive && s.item_muddy}`}> Конструктор</p>
                            </>
                        }
                    </NavLink>
                    <NavLink to='/feed' className={`${s.item} p-4`}>
                        {({isActive}) =>
                            <>
                                <ListIcon type={isActive ? 'primary' : 'secondary'}/>
                                <p className={`text text_type_main-default ml-2 ${!isActive && s.item_muddy}`}>Лента заказов</p>
                            </>
                        }
                    </NavLink>
                </div>

                <NavLink to='/' className={s.logo}>
                    <Logo/>
                </NavLink>
                <div>
                    <NavLink state={{index: location}} to='/profile' className={`${s.item} p-4`}>
                        {({isActive}) =>
                            <>
                                <ProfileIcon type={isActive ? 'primary' : 'secondary'}/>
                                <div style={{position: 'relative'}} className={`ml-2 text text_type_main-default ${!isActive && s.item_muddy}`}>
                                    {isPending && <SmallPreloader/>}
                                    {name}</div>
                            </>
                        }
                    </NavLink>
                </div>
            </div>
        </header>
    );
};
