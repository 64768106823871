import {AppHeader} from '../app-header/app-header';
import {FC, useCallback, useEffect} from "react";
import {loadIngredients} from "../../store/all-ingredients/actions";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {HomePage} from "../../pages/home-page/home-page";
import {LoginPage} from "../../pages/login-page/login-page";
import {RegisterPage} from "../../pages/register-page/register-page";
import {ForgotPassword} from "../../pages/forgot-password/forgot-password";
import {ResetPassword} from "../../pages/reset-password/reset-password";
import {IngredientDetails} from "../burger-ingredients/ingredient-details/ingredient-details";
import {createModal} from "../../utils/create-modal";
import {Profile,} from "../../pages/profile/profile";
import {NotFoundPage} from "../../pages/not-found/not-found-page";
import {fetchUserDataAction} from "../../store/user-auth/actions";
import {UserInfo} from "../../pages/profile/nested/user-info";
import {OrderHistory} from "../../pages/profile/nested/order-history";
import {Protected} from "../../pages/protected";
import {Feed} from "../../pages/feed/feed";
import {FeedOrderDetails} from "../feed-order/feed-order-details/feed-order-details";
import {useDispatch} from "../../store/hooks.";

export const App: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const closeModalIfPageWereOpenAsLink = useCallback(() => navigate('/'), [navigate]);
    const closeModalIfPageWereOpenAsLinkForFeed = useCallback(() => navigate('/feed'), [navigate]);
    const closeModalIfPageWereOpenAsLinkForOrders = useCallback(() => navigate('/profile/orders'), [navigate]);

    useEffect(() => {
        dispatch(loadIngredients());
        dispatch(fetchUserDataAction());
    }, [dispatch]);

    const location = useLocation();
    let state = location.state

    return (
        <div className='mb-10'>
            <AppHeader/>
            <Routes location={state?.backgroundLocation || location}>
                <Route index element={<HomePage/>}/>
                <Route path='/ingredients/:id'
                       element={createModal(<IngredientDetails/>, closeModalIfPageWereOpenAsLink)}/>

                <Route path='/feed' element={<Feed/>}/>
                <Route path='/feed/:id'
                       element={createModal(<FeedOrderDetails/>, closeModalIfPageWereOpenAsLinkForFeed)}/>

                <Route path='/login' element={
                    <Protected onlyUnAuth>
                        <LoginPage/>
                    </Protected>
                }/>
                <Route path='/register' element={
                    <Protected onlyUnAuth>
                        <RegisterPage/>
                    </Protected>
                }/>
                <Route path='/forgot-password' element={
                    <Protected onlyUnAuth>
                        <ForgotPassword/>
                    </Protected>
                }/>
                <Route path='/reset-password' element={
                    <Protected onlyUnAuth>
                        <ResetPassword/>
                    </Protected>
                }/>

                <Route
                    path="/profile"
                    element={
                        <Protected>
                            <Profile/>
                        </Protected>
                    }
                >
                    <Route path='/profile' element={<UserInfo/>}></Route>
                    <Route path='/profile/orders' element={<OrderHistory/>}></Route>
                    <Route path='/profile/orders/:id'
                           element={createModal(<FeedOrderDetails/>, closeModalIfPageWereOpenAsLinkForOrders)}></Route>
                </Route>

                <Route path='*' element={<NotFoundPage/>}/>
            </Routes>
            {state?.backgroundLocation && (
                <Routes>
                    <Route path="/ingredients/:id" element={createModal(<IngredientDetails/>)}/>
                    <Route path="/feed/:id" element={createModal(<FeedOrderDetails/>)}/>
                    <Route path="/profile/orders/:id" element={createModal(<FeedOrderDetails/>)}/>
                </Routes>
            )}

        </div>
    );
}
