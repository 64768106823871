import React, {FC} from "react";
import s from './order-row.module.scss';
import {CurrencyIcon} from "@ya.praktikum/react-developer-burger-ui-components";
import {TIngredient} from "../../../types/t-ingredient";

export const OrderRow: FC<{ ingredient? : TIngredient, number?: number }> =
    ({ingredient, number = 1}) => {
    return (
        <div className={`${s.wrapper}`}>

            <div className={`${s.desc_block}`}>
                <img className={`${s.img}`}
                     src={ingredient?.image_mobile}
                     alt="ingredient"/>
                <p className="mt-1 text text_type_main-default">{ingredient?.name}</p>
            </div>

            <div className={`${s.price_block}`}>
                <p className="mr-2 text text_type_digits-default">{`${number} x ${ingredient?.price}`}</p>
                <CurrencyIcon type="primary"/>
            </div>

        </div>
    )
}