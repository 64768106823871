import s from './modal.module.scss';
import ReactDom from "react-dom";
import {ModalOverlay} from "../modal-overlay/modal-overlay";
import {CloseIcon} from "@ya.praktikum/react-developer-burger-ui-components";

import {FC, ReactNode, useEffect} from "react";
import {useNavigate} from "react-router-dom";


const modalRoot = document.getElementById('modal-root');
type TModal = {
    children: ReactNode,
    closeModal?: () => void
}

export const Modal: FC<TModal> = ({children, closeModal}) => {
    const navigate = useNavigate();

    if (!closeModal) {
        closeModal = () => navigate(-1);
    }

    const handleEsc = (e: KeyboardEvent) => e.key === 'Escape' && (closeModal && closeModal());

    useEffect(() => {
        document.addEventListener("keydown", handleEsc);
        return () => document.removeEventListener("keydown", handleEsc);
    }, [closeModal]);

    return ReactDom.createPortal(
        <>
            <ModalOverlay closeModal={closeModal}/>
            <div className={`${s.wrapper} pt-10 pr-10 pl-10 pb-10`}>
                <span className={s.close} data-test="modal-close-icon">
                     <CloseIcon onClick={closeModal} type="primary"/>
                </span>
                {children}
            </div>
        </>,
        modalRoot as HTMLDivElement
    )
}

