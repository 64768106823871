import s from './ingredient-details.module.scss';
import {getIngredients} from "../../../store/all-ingredients/selectors";
import {useParams} from "react-router-dom";
import {FC} from "react";
import {TIngredient} from "../../../types/t-ingredient";
import {useSelector} from "../../../store/hooks.";

export const IngredientDetails: FC = () => {
    const {id} = useParams();

    const activeIngredient = useSelector(getIngredients).find((el: TIngredient) => el._id === id);

    return (
        <>
            <div className={s.header}>
                <h3 className='text text_type_main-large'>Детали ингредиента</h3>
            </div>

            <div className='pl-15 pr-15'>

                <div className={s.img}>
                    <img src={activeIngredient?.image_large} alt='ingredient'/>
                </div>
                <h4 className='text_type_main-medium mt-4'>{activeIngredient?.name}</h4>
                <div className={`${s.wrapper} text text_type_main-default mt-8`}>
                    <div className={s.desc}>
                        <p className='mb-2'>Калории, ккал</p>
                        <p className='text_type_digits-default'>{activeIngredient?.calories}</p>
                    </div>
                    <div className={s.desc}>
                        <p className='mb-2'>Белки, г</p>
                        <p className='text_type_digits-default '>{activeIngredient?.proteins}</p>
                    </div>
                    <div className={s.desc}>
                        <p className='mb-2'>Жиры, г</p>
                        <p className='text_type_digits-default'>{activeIngredient?.fat}</p>
                    </div>
                    <div className={s.desc}>
                        <p className='mb-2'>Углеводы, г</p>
                        <p className='text_type_digits-default'>{activeIngredient?.carbohydrates}</p>
                    </div>
                </div>
            </div>
        </>
    )
}
