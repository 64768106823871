import {TAllOrdersActions} from "./actions";
import {
    ALL_ORDERS_ON_CLOSE,
    ALL_ORDERS_ON_ERROR,
    ALL_ORDERS_ON_OPEN,
    ALL_ORDERS_ON_MESSAGE
} from "./const";
import {TWsOrder} from "../../types/t-ws-order";

export type TAllOrdersState = {
    orders: TWsOrder[];
    total: number;
    totalToday: number;
    isOpen: boolean;
    connectionError: Event | null;
};

export const initialState: TAllOrdersState = {
    orders: [],
    total: 0,
    totalToday: 0,
    isOpen: false,
    connectionError: null,
};

export const reducer = (
    state = initialState,
    action: TAllOrdersActions
): TAllOrdersState => {
    switch (action.type) {
        case ALL_ORDERS_ON_OPEN: {
            return {
                ...state,
                isOpen: true,
                connectionError: null,
            };
        }
        case ALL_ORDERS_ON_MESSAGE: {
            const {orders, total, totalToday} = action.payload.data;
            return {
                ...state,
                orders,
                total,
                totalToday,
            };
        }
        case ALL_ORDERS_ON_ERROR: {
            return {
                ...state,
                isOpen: false,
                connectionError: action.payload,
            };
        }
        case ALL_ORDERS_ON_CLOSE: {
            return {
                ...state,
                isOpen: false,
            };
        }

        default: return state;
    }
};
