import React, {FC, FormEvent} from 'react';
import s from '../../common-styles/form.module.scss';
import {Button, EmailInput, Input, PasswordInput} from '@ya.praktikum/react-developer-burger-ui-components';
import {Link} from 'react-router-dom';
import {registerAction} from "../../store/user-auth/actions";
import {useForm} from "../../hooks/use-form";
import {useDispatch} from "../../store/hooks.";

export const RegisterPage: FC = () => {
    const dispatch = useDispatch();

    const {values, handleChange} = useForm({
        email: '',
        password: '',
        name: ''
    });


    const submitRegister = (e: FormEvent) => {
        e.preventDefault();

        dispatch(registerAction({email: values.email, name: values.name, password: values.password}))
    }


    return (
        <div className={s.wrapper}>

            <form className={`${s.form}`} onSubmit={e => submitRegister(e)}>

                <h3 className='text text_type_main-medium mb-6'>Регистрация</h3>

                <Input
                    required={true}
                    extraClass='mb-6'
                    type='text'
                    placeholder='Имя'
                    onChange={handleChange}
                    value={values.name}
                    name={'name'}
                    size='default'
                />

                <EmailInput
                    required={true}
                    name={'email'}
                    extraClass='mb-6'
                    onChange={handleChange}
                    value={values.email}
                />

                <PasswordInput
                    required={true}
                    name={'password'}
                    extraClass='mb-6'
                    onChange={handleChange}
                    value={values.password}
                />

                <Button
                    extraClass='mb-20'
                    type='primary'
                    size='large'
                    htmlType='submit'>
                    Зарегистрироваться
                </Button>

                <div className={`${s.link_wrapper} mb-4 text text_type_main-default`}>
                    <p className={s.text}>Уже зарегистрированы?</p>
                    <Link className={`${s.link} ml-2`} to='/login'>Войти</Link>
                </div>
            </form>
        </div>
    )
}