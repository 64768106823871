import {
    ADD_BUN_TO_CONSTRUCTOR,
    ADD_INGREDIENT_TO_CONSTRUCTOR,
    DELETE_INGREDIENT_FROM_CONSTRUCTOR,
    REORDER_INGREDIENTS_IN_CONSTRUCTOR, RESET_INGREDIENTS_IN_CONSTRUCTOR
} from "./const";
import {TIngredientsInConstructorActions} from "./actions";
import {TUpdatedIngredient} from "../../types/t-ingredient";

type TIngredientsInConstructorState = {
    bunsInConstructor: TUpdatedIngredient[],
    mainAndSauceInConstructor: TUpdatedIngredient[],
    totalBuns: number,
    totalMainAndSauce: number,
    isOrderButtonEnabled: boolean
}

export const initialState: TIngredientsInConstructorState = {
    bunsInConstructor: [],
    mainAndSauceInConstructor: [],
    totalBuns: 0,
    totalMainAndSauce: 0,
    isOrderButtonEnabled: false
}
export const reducer = (state = initialState, action: TIngredientsInConstructorActions): TIngredientsInConstructorState => {
    switch (action.type) {

        case ADD_BUN_TO_CONSTRUCTOR:
            return {
                ...state,
                bunsInConstructor: [action.payload, action.payload],
                totalBuns: (action.payload.price * 2),
                isOrderButtonEnabled: checkIfButtonShouldEnabled([action.payload, action.payload], state.mainAndSauceInConstructor)
            }

        case ADD_INGREDIENT_TO_CONSTRUCTOR:
            return {
                ...state,
                mainAndSauceInConstructor: [...state.mainAndSauceInConstructor, action.payload],
                totalMainAndSauce: state.totalMainAndSauce += action.payload.price,
                isOrderButtonEnabled: checkIfButtonShouldEnabled(state.bunsInConstructor, [...state.mainAndSauceInConstructor, action.payload])

            }

        case DELETE_INGREDIENT_FROM_CONSTRUCTOR:
            return {
                ...state,
                mainAndSauceInConstructor: state.mainAndSauceInConstructor.filter(el => el.uiId !== action.payload.uiId),
                totalMainAndSauce: state.totalMainAndSauce -= action.payload.price,
                isOrderButtonEnabled: checkIfButtonShouldEnabled(state.bunsInConstructor,
                    state.mainAndSauceInConstructor.filter(el => el.uiId !== action.payload.uiId))
            }

        case REORDER_INGREDIENTS_IN_CONSTRUCTOR:
            const {to, from} = action.payload;
            const ingredients = [...state.mainAndSauceInConstructor];

            if (action.payload.from !== undefined) {
                [ingredients[to], ingredients[from]] = [ingredients[from], ingredients[to]]
            }

            return {
                ...state,
                mainAndSauceInConstructor: ingredients
            }
        case RESET_INGREDIENTS_IN_CONSTRUCTOR:
            return initialState;

        default:
            return state;
    }
}

const checkIfButtonShouldEnabled = (bunArr: TUpdatedIngredient [], ingredientArr: TUpdatedIngredient []) => {
    return bunArr.length === 2 && ingredientArr.length > 0;
}