import {ChangeEvent, FC, FormEvent, useEffect, useState} from "react";
import s from '../../common-styles/form.module.scss';
import {Button, Input, PasswordInput} from "@ya.praktikum/react-developer-burger-ui-components";
import {Link, useNavigate} from "react-router-dom";
import {resetSelector} from "../../store/user-auth/selectors";
import {clearErr, resetPasswordWithTokenFromEmailAction} from "../../store/user-auth/actions";
import {ResetStatus} from "../../types/reset-status";
import {useDispatch, useSelector} from "../../store/hooks.";

export const ResetPassword: FC = () => {
    const dispatch = useDispatch();

    const [password, setPassword] = useState("");
    const [token, setToken] = useState("");

    const reset = useSelector(resetSelector);
    const navigate = useNavigate();


    useEffect(() => {
        if (reset === ResetStatus.OFF || reset === ResetStatus.FORGOT) {
            navigate('/forgot-password');

        } else if (reset === ResetStatus.FINISH) {
            navigate('/');
        }
    }, [reset, navigate])

    const resetPassword = (e: FormEvent) => {
        e.preventDefault();

        dispatch(resetPasswordWithTokenFromEmailAction({password, token}))
    }

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setToken(e.target.value);

        dispatch(clearErr());
    }

    return (
        <div className={s.wrapper}>
            <form className={`${s.form}`} onSubmit={resetPassword}>
                <h3 className="text text_type_main-medium mb-6">Восстановление пароля</h3>

                <PasswordInput
                    extraClass='mb-6'
                    placeholder='Введите новый пароль'
                    onChange={e => setPassword(e.target.value)}
                    value={password}
                    size='default'
                />

                <Input
                    extraClass='mb-6'
                    type='text'
                    placeholder='Введите код из письма'
                    onChange={handleInputChange}
                    value={token}
                    error={false}
                    errorText=''
                    size='default'
                />

                <Button
                    extraClass='mb-20'
                    type="primary"
                    size="large"
                    htmlType='submit'>
                    Сохранить
                </Button>

                <div className={`${s.link_wrapper} mb-4 text text_type_main-default`}>
                    <p className={s.text}>Вспомнили пароль?</p>
                    <Link className={`${s.link} ml-2`} to='/login'>Войти</Link>

                </div>
            </form>
        </div>
    )
}