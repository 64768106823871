import {FC} from "react";
import notFoundPage from '../../images/not-found-page.png';
import s from './not-found-page.module.scss'


export const NotFoundPage: FC = () => {

    return (
        <div className={`pt-30 ${s.wrapper}`}>
            <h1 className={`text_type_main-large ${s.title}`}>Страница не найдена</h1>
            <img className={s.img} src={notFoundPage} alt='пустая бургерная'/>
        </div>
    )
}