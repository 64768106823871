import {
    fetchUserDataRq,
    loginRq,
    logoutRq,
    registerRq,
    resetPasswordRq,
    resetPasswordSendMessageFromEmailRq,
    updateUserDataRq
} from "../../client/api-client";
import {getBearerToken, removeTokens, setTokens} from "../../utils/token-handler";
import {
    CLEAR_ERR,
    GET_USER_INFO_ERROR,
    GET_USER_INFO_PENDING,
    GET_USER_INFO_SUCCESS,
    LOGIN_USER_ERROR,
    LOGIN_USER_PENDING,
    LOGIN_USER_SUCCESS,
    LOGOUT_ERROR,
    LOGOUT_PENDING,
    LOGOUT_SUCCESS, REGISTER_USER_ERROR,
    REGISTER_USER_PENDING,
    REGISTER_USER_SUCCESS,
    RESET_PASSWORD_STEP_1_ERROR,
    RESET_PASSWORD_STEP_1_PENDING,
    RESET_PASSWORD_STEP_1_SUCCESS,
    RESET_PASSWORD_STEP_2_ERROR,
    RESET_PASSWORD_STEP_2_PENDING,
    RESET_PASSWORD_STEP_2_SUCCESS,
    UPDATE_USER_INFO_ERROR,
    UPDATE_USER_INFO_PENDING,
    UPDATE_USER_INFO_SUCCESS
} from "./const";
import {AppDispatch, AppThunk} from "../store";
import {TUserData} from "../../types/t-user-data";
import {TUserSign} from "../../types/t-user-sign";


export interface IRegisterUserPending {
    readonly type: typeof REGISTER_USER_PENDING
}

export interface IRegisterUserSuccess {
    readonly type: typeof REGISTER_USER_SUCCESS;
    readonly payload: TUserSign;
}

export interface IRegisterUserError {
    readonly type: typeof REGISTER_USER_ERROR
    readonly payload: TInfoMessage;
}


export type TInfoMessage = { success: boolean, message: string, status?: string }
export const registerAction: ({email, name, password}: TUserData) => AppThunk = ({
                                                                                     email,
                                                                                     name,
                                                                                     password
                                                                                 }) => (dispatch: AppDispatch) => {
    dispatch({
        type: REGISTER_USER_PENDING
    })
    return registerRq({email, name, password})
        .then(rs => {
            setTokens(rs);

            dispatch({
                type: REGISTER_USER_SUCCESS,
                payload: rs
            })
        })
        .catch(err => {
            err.reject.then((body: TInfoMessage) => {
                dispatch({
                    type: REGISTER_USER_ERROR,
                    payload: {...body, status: err.status}
                })
            })
        })
}

export interface ILoginUserPending {
    readonly type: typeof LOGIN_USER_PENDING
}

export interface ILoginUserSuccess {
    readonly type: typeof LOGIN_USER_SUCCESS;
    readonly payload: TUserSign;
}

export interface ILoginUserError {
    readonly type: typeof LOGIN_USER_ERROR;
    readonly payload: TInfoMessage;
}

export const loginAction: ({email, password}: TUserData) => AppThunk = ({
                                                                            email,
                                                                            password
                                                                        }) => (dispatch: AppDispatch) => {
    dispatch({
        type: LOGIN_USER_PENDING
    })
    return loginRq({email, password})
        .then(rs => {
            setTokens(rs);

            dispatch({
                type: LOGIN_USER_SUCCESS,
                payload: rs
            })
        })
        .catch((err) => {
            err.reject.then((body: TInfoMessage) => {
                dispatch({
                    type: LOGIN_USER_ERROR,
                    payload: {...body, status: err.status}
                })
            })
        })
}


export interface IGetUserInfoPending {
    readonly type: typeof GET_USER_INFO_PENDING
}

export interface IGetUserInfoSuccess {
    readonly type: typeof GET_USER_INFO_SUCCESS;
    payload: TUserSign
}

export interface IGetUserInfoError {
    readonly type: typeof GET_USER_INFO_ERROR;
    payload: TInfoMessage
}

export const fetchUserDataAction: () => AppThunk = () => (dispatch: AppDispatch) => {
    if (getBearerToken()) {
        dispatch({
            type: GET_USER_INFO_PENDING
        })

        fetchUserDataRq()
            .then(rs => {
                dispatch({
                    type: GET_USER_INFO_SUCCESS,
                    payload: rs
                })
            })
            .catch(err => {
                err.reject.then((body: TInfoMessage) => {
                    dispatch({
                        type: GET_USER_INFO_ERROR,
                        payload: {...body, status: err.status}
                    })
                })
            })
    }
}

export interface IGetLogoutPending {
    readonly type: typeof LOGOUT_PENDING
}

export interface IGetLogoutSuccess {
    readonly type: typeof LOGOUT_SUCCESS;
    payload: TInfoMessage
}

export interface IGetLogoutError {
    readonly type: typeof LOGOUT_ERROR;
    payload: TInfoMessage
}

export const logoutAction: () => AppThunk = () => (dispatch: AppDispatch) => {
    dispatch({
        type: LOGOUT_PENDING
    })
    logoutRq()
        .then(rs => {
            removeTokens();
            dispatch({
                type: LOGOUT_SUCCESS,
                payload: rs
            })
        })
        .catch(err => {
            err.reject.then((body: TInfoMessage) => {
                dispatch({
                    type: LOGOUT_ERROR,
                    payload: {...body, status: err.status}
                })
            })
        })
}

export interface IClearErr {
    readonly type: typeof CLEAR_ERR
}

export const clearErr: () => AppThunk = () => (dispatch: AppDispatch) => {
    dispatch({
        type: CLEAR_ERR,
    })
}

export interface IResetPasswordStep1Pending {
    readonly type: typeof RESET_PASSWORD_STEP_1_PENDING
}

export interface IResetPasswordStep1Success {
    readonly type: typeof RESET_PASSWORD_STEP_1_SUCCESS;
    payload: TInfoMessage
}

export interface IResetPasswordStep1Error {
    readonly type: typeof RESET_PASSWORD_STEP_1_ERROR;
    payload: TInfoMessage
}

export const resetPasswordAction: ({email}: TUserData) => AppThunk = ({email}) => (dispatch: AppDispatch) => {
    dispatch({
        type: RESET_PASSWORD_STEP_1_PENDING
    })
    resetPasswordRq(email)
        .then(rs => {
            dispatch({
                type: RESET_PASSWORD_STEP_1_SUCCESS,
                payload: rs
            })
        })
        .catch(err => {
            err.reject.then((body: TInfoMessage) => {

                dispatch({
                    type: RESET_PASSWORD_STEP_1_ERROR,
                    payload: {...body, status: err.status}
                })
            })
        })
}

export interface IResetPasswordStep2Pending {
    readonly type: typeof RESET_PASSWORD_STEP_2_PENDING
}

export interface IResetPasswordStep2Success {
    readonly type: typeof RESET_PASSWORD_STEP_2_SUCCESS;
    payload: TInfoMessage
}

export interface IResetPasswordStep2Error {
    readonly type: typeof RESET_PASSWORD_STEP_2_ERROR;
    payload: TInfoMessage
}

export const resetPasswordWithTokenFromEmailAction: ({
                                                         password,
                                                         token
                                                     }: { password: string, token: string }) => AppThunk =
    ({password, token}) => (dispatch: AppDispatch) => {
        dispatch({
            type: RESET_PASSWORD_STEP_2_PENDING
        })
        resetPasswordSendMessageFromEmailRq({password, token})
            .then(rs => {
                dispatch({
                    type: RESET_PASSWORD_STEP_2_SUCCESS,
                    payload: rs
                })
            })
            .catch(err => {
                err.reject.then((body: TInfoMessage) => {
                    dispatch({
                        type: RESET_PASSWORD_STEP_2_ERROR,
                        payload: {...body, status: err.status}
                    })
                })
            })
    }


export interface IUpdateUserInfoPending {
    readonly type: typeof UPDATE_USER_INFO_PENDING
}

export interface IUpdateUserInfoSuccess {
    readonly type: typeof UPDATE_USER_INFO_SUCCESS;
    payload: TUserSign
}

export interface IUpdateUserInfoError {
    readonly type: typeof UPDATE_USER_INFO_ERROR;
    payload: TInfoMessage
}

export const updateUserDataAction: (userData: TUserData) => AppThunk = (userData) => (dispatch: AppDispatch) => {
    dispatch({
        type: UPDATE_USER_INFO_PENDING
    })
    updateUserDataRq(userData)
        .then(rs => {
            dispatch({
                type: UPDATE_USER_INFO_SUCCESS,
                payload: rs
            })
        })
        .catch(err => {
            err.reject.then((body: TInfoMessage) => {
                dispatch({
                    type: UPDATE_USER_INFO_ERROR,
                    payload: {...body, status: err.status}
                })
            })
        })
}


export type TAuthActions =
    | IRegisterUserPending
    | IRegisterUserSuccess
    | IRegisterUserError
    | ILoginUserPending
    | ILoginUserSuccess
    | ILoginUserError
    | IGetUserInfoPending
    | IGetUserInfoError
    | IGetUserInfoSuccess
    | IGetLogoutPending
    | IGetLogoutSuccess
    | IGetLogoutError
    | IClearErr
    | IResetPasswordStep1Pending
    | IResetPasswordStep1Success
    | IResetPasswordStep1Error
    | IResetPasswordStep2Pending
    | IResetPasswordStep2Success
    | IResetPasswordStep2Error
    | IUpdateUserInfoPending
    | IUpdateUserInfoSuccess
    | IUpdateUserInfoError



