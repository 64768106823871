import {fetchIngredientsRq} from "../../client/api-client";
import {GET_ALL_INGREDIENTS_ERROR, GET_ALL_INGREDIENTS_PENDING, GET_ALL_INGREDIENTS_SUCCESS} from "./const";
import {AppDispatch, AppThunk} from "../store";
import {TIngredientsRs} from "../../types/t-ingredients-rs";


export interface IGetIngredientsPending {
    readonly type: typeof GET_ALL_INGREDIENTS_PENDING;
}

export interface IGetIngredientsSuccess {
    readonly type: typeof GET_ALL_INGREDIENTS_SUCCESS;
    readonly payload: TIngredientsRs;
}

export interface IGetIngredientsError {
    readonly type: typeof GET_ALL_INGREDIENTS_ERROR;
    readonly payload: { status: string, body: object }
}

export type TGetIngredientActions =
    | IGetIngredientsPending
    | IGetIngredientsSuccess
    | IGetIngredientsError

export const loadIngredients: () => AppThunk = () => (dispatch: AppDispatch) => {
    dispatch({
        type: GET_ALL_INGREDIENTS_PENDING
    })
    fetchIngredientsRq()
        .then(rs => {
            dispatch({
                type: GET_ALL_INGREDIENTS_SUCCESS,
                payload: rs
            })
        })
        .catch(err => {
            err.reject.then((body: any) => {
                dispatch({
                    type: GET_ALL_INGREDIENTS_ERROR,
                    payload: {...body, status: err.status}
                })
            })
        })
}

