import {
    USER_ORDERS_CLOSE,
    USER_ORDERS_INIT,
    USER_ORDERS_ON_CLOSE,
    USER_ORDERS_ON_ERROR,
    USER_ORDERS_ON_MESSAGE,
    USER_ORDERS_ON_OPEN
} from "./const";
import {AppDispatch, AppThunk} from "../store";
import {WS_ORDERS, WS_URL} from "../../client/ws-client";
import {getBearerToken} from "../../utils/token-handler";
import {TWsOrder} from "../../types/t-ws-order";

export interface IUserOrdersConnectionInit {
    readonly type: typeof USER_ORDERS_INIT;
    readonly payload: string;
}

export interface IUserOrdersConnectionClose {
    readonly type: typeof USER_ORDERS_CLOSE;
}


export interface IUserOrdersConnectionOnOpen {
    readonly type: typeof USER_ORDERS_ON_OPEN;
    readonly payload: Event;
}

export interface IUserOrdersConnectionOnError {
    readonly type: typeof USER_ORDERS_ON_ERROR;
    readonly payload: Event;
}

export interface IUserOrdersConnectionOnClose {
    readonly type: typeof USER_ORDERS_ON_CLOSE;
    readonly payload: Event;
}



export interface IUserOrdersConnectionOnMessage {
    readonly type: typeof USER_ORDERS_ON_MESSAGE;
    readonly payload: {
        data: {
            orders: TWsOrder[];
        };
        createdAt: number;
    };
}

export type TUSerOrdersActions =
    | IUserOrdersConnectionInit
    | IUserOrdersConnectionOnOpen
    | IUserOrdersConnectionOnError
    | IUserOrdersConnectionOnClose
    | IUserOrdersConnectionClose
    | IUserOrdersConnectionOnMessage;


export const initUserOrdersConnection: () => AppThunk = () => (dispatch: AppDispatch) => {
    dispatch({
        type: USER_ORDERS_INIT,
        payload: WS_URL + WS_ORDERS + getBearerToken()
    })
}

export const closeUserOrdersConnection: () => AppThunk  = () => (dispatch: AppDispatch) => {
    dispatch({
        type: USER_ORDERS_CLOSE
    })
}