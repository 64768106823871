import {
    ALL_ORDERS_CLOSE,
    ALL_ORDERS_ON_CLOSE,
    ALL_ORDERS_ON_ERROR,
    ALL_ORDERS_INIT,
    ALL_ORDERS_ON_OPEN,
    ALL_ORDERS_ON_MESSAGE
} from "./const";
import {AppDispatch, AppThunk} from "../store";
import {WS_ORDERS_ALL, WS_URL} from "../../client/ws-client";
import {TWsOrder} from "../../types/t-ws-order";

export interface IAllOrdersConnectionInit {
    readonly type: typeof ALL_ORDERS_INIT;
    readonly payload: string;
}

export interface IAllOrdersConnectionClose {
    readonly type: typeof ALL_ORDERS_CLOSE;
}

export interface IAllOrdersConnectionOnOpen {
    readonly type: typeof ALL_ORDERS_ON_OPEN;
    readonly payload: Event;
}

export interface IAllOrdersConnectionOnError {
    readonly type: typeof ALL_ORDERS_ON_ERROR;
    readonly payload: Event;
}
export interface IAllOrdersConnectionOnClose {
    readonly type: typeof ALL_ORDERS_ON_CLOSE;
    readonly payload: Event;
}


export interface IAllOrdersOnMessage {
    readonly type: typeof ALL_ORDERS_ON_MESSAGE;
    readonly payload: {
        data: {
            orders: TWsOrder[];
            total: number;
            totalToday: number;
        };
        createdAt: number;
    };
}

export type TAllOrdersActions =
    | IAllOrdersConnectionInit
    | IAllOrdersConnectionOnOpen
    | IAllOrdersConnectionOnError
    | IAllOrdersConnectionOnClose
    | IAllOrdersConnectionClose
    | IAllOrdersOnMessage;

export const initAllOrdersConnection: () => AppThunk = () => (dispatch: AppDispatch) => {
    dispatch({
        type: ALL_ORDERS_INIT,
        payload: WS_URL + WS_ORDERS_ALL
    })
}

export const closeAllOrdersConnection: () => AppThunk  = () => (dispatch: AppDispatch) => {
    dispatch({
        type: ALL_ORDERS_CLOSE
    })
}