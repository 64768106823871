import {
    CLEAR_ERR,
    GET_USER_INFO_ERROR,
    GET_USER_INFO_PENDING,
    GET_USER_INFO_SUCCESS,
    LOGIN_USER_ERROR,
    LOGIN_USER_PENDING,
    LOGIN_USER_SUCCESS, LOGOUT_ERROR, LOGOUT_PENDING,
    LOGOUT_SUCCESS,
    REGISTER_USER_ERROR,
    REGISTER_USER_PENDING,
    REGISTER_USER_SUCCESS,
    RESET_PASSWORD_STEP_1_ERROR,
    RESET_PASSWORD_STEP_1_PENDING,
    RESET_PASSWORD_STEP_1_SUCCESS,
    RESET_PASSWORD_STEP_2_ERROR,
    RESET_PASSWORD_STEP_2_PENDING,
    RESET_PASSWORD_STEP_2_SUCCESS,
    UPDATE_USER_INFO_ERROR,
    UPDATE_USER_INFO_PENDING,
    UPDATE_USER_INFO_SUCCESS
} from "./const";
import {ResetStatus} from "../../types/reset-status";
import {TAuthActions} from "./actions";
import {TUserData} from "../../types/t-user-data";

export type TAuthState = {
    isAuth: boolean,
    user: TUserData | any,
    isPending: boolean,
    isError: boolean,
    error: any,
    reset: ResetStatus,
}

export const initialState: TAuthState = {
    isAuth: false,
    user: null,
    isPending: false,
    isError: false,
    error: null,
    reset: ResetStatus.OFF,
}

export const reducer = (state = initialState, action: TAuthActions): TAuthState => {
    switch (action.type) {
        case REGISTER_USER_SUCCESS:
        case LOGIN_USER_SUCCESS:
        case GET_USER_INFO_SUCCESS:
        case UPDATE_USER_INFO_SUCCESS:
            return {
                ...state,
                isAuth: true,
                user: action.payload.user,
                isPending: false,
                isError: false,
                error: null,
                reset: ResetStatus.OFF,
            }

        case LOGOUT_SUCCESS:
            return {
                ...state,
                isAuth: false,
                user: null,
                isPending: false,
                error: null,
            }
        case LOGOUT_PENDING:
            return {
                ...state,
                isAuth: false,
                user: null,
                isPending: true,
                error: null,
            }
        case LOGOUT_ERROR:
            return {
                ...state,
                isAuth: false,
                user: null,
                isPending: false,
                error: action.payload,
            }
        case REGISTER_USER_PENDING:
        case LOGIN_USER_PENDING:
        case GET_USER_INFO_PENDING:
            return {
                ...state,
                isAuth: false,
                isPending: true,
                isError: false,
                error: null,
            }

        case REGISTER_USER_ERROR:
        case LOGIN_USER_ERROR:
            return {
                ...state,
                isAuth: false,
                user: null,
                isPending: false,
                isError: true,
                error: action.payload,
            }

        case GET_USER_INFO_ERROR:
            return {
                ...state,
                isPending: false,
                isError: true,
                error: action.payload,
            }
        case UPDATE_USER_INFO_PENDING:
            return {
                ...state,
                isPending: true,
                isError: false,
                error: null,
            }
        case UPDATE_USER_INFO_ERROR:
            return {
                ...state,
                isPending: false,
                isError: true,
                error: action.payload,
            }
        case CLEAR_ERR:
            return {
                ...state,
                error: null,
                isError: false
            }
        case RESET_PASSWORD_STEP_1_PENDING:
            return {
                ...state,
                isPending: true,
                isError: false,
                error: null,
                reset: ResetStatus.FORGOT
            }

        case RESET_PASSWORD_STEP_1_ERROR:
            return {
                ...state,
                isPending: false,
                isError: true,
                error: action.payload,
                reset: ResetStatus.FORGOT
            }
        case RESET_PASSWORD_STEP_1_SUCCESS:
            return {
                ...state,
                isPending: false,
                isError: false,
                error: null,
                reset: ResetStatus.RESET
            }
        case RESET_PASSWORD_STEP_2_PENDING:
            return {
                ...state,
                isPending: true,
                isError: false,
                error: null,
                reset: ResetStatus.RESET
            }

        case RESET_PASSWORD_STEP_2_ERROR:
            return {
                ...state,
                isPending: false,
                isError: true,
                error: action.payload,
                reset: ResetStatus.RESET
            }

        case RESET_PASSWORD_STEP_2_SUCCESS:
            return {
                ...state,
                isPending: false,
                isError: false,
                error: null,
                reset: ResetStatus.FINISH
            }
        default:
            return state;
    }
}