import {createModal} from "../../utils/create-modal";
import {clearErr} from "../../store/user-auth/actions";
import {errorSelector} from "../../store/user-auth/selectors";
import {BurgerIcon} from "@ya.praktikum/react-developer-burger-ui-components";
import {FC} from "react";
import {useDispatch, useSelector} from "../../store/hooks.";


type TErrModal = {
    success: boolean;
    message: string;
    status: number;
}
export const ErrorModal = () => {
    const dispatch = useDispatch();
    const error: TErrModal = useSelector(errorSelector);

    const closeModal = () => {
        dispatch(clearErr());
    }

    return createModal(<ErrContent error={error}/>, closeModal);
}


const ErrContent: FC<{ error: TErrModal }> = ({error}) => {
    return (
        <>
            <h2 style={{textAlign: 'center'}}
                className='text text_type_main-large text_color_error'>Ошибка {error?.status}:</h2>
            <h2 style={{textAlign: 'center'}} className='text text_type_main-medium text_color_error'>
                <BurgerIcon type="error"/> {error?.message}
            </h2>
        </>

    )
}