import React, {FC} from "react";
import {OrderCard} from "../order-card/order-card";
import s from './order-list.module.scss'
import scroll from '../../../common-styles/scroll.module.scss';
import {TWsOrder} from "../../../types/t-ws-order";

export const OrderList: FC<{ orders: TWsOrder[], link : string}> = ({orders, link}) => {

    return (
        <div className={`${s.order_list} ${scroll.scroll} mr-15`}>
            {orders?.map((order: TWsOrder) => (
                <OrderCard
                    key={order.createdAt}
                    order={order}
                    link={link}
                />
            ))}
        </div>
    )
}
