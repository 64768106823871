import s from './order-details.module.scss';
import done from '../../../images/done.png';
import {getOrderNumber} from "../../../store/create-order/selectors";
import {FC} from "react";
import {useSelector} from "../../../store/hooks.";

export const OrderDetails: FC = () => {
    const orderNumber = useSelector(getOrderNumber);

    return (
        <div className={`${s.order} pl-15 pr-15`}>
            <p className='mt-4 text text_type_digits-large' data-test='order-number'>{orderNumber}</p>
            <p className='mt-8 text text_type_main-medium'>идентификатор заказа</p>
            <img className='mt-15' src={done} alt='ok'/>
            <p className='mt-15 text text_type_main-medium'>Ваш заказ начали готовить</p>
            <p className={`${s.infoMessage} mt-2 text text_type_main-medium`}>
                Дождитесь готовности на орбитальной станции
            </p>
        </div>
    )
}