import {TUserSign} from "../types/t-user-sign";

export const BEARER_TOKEN = 'bearerToken';
export const REFRESH_TOKEN = 'refreshToken';

export const setTokens = (rs: TUserSign) => {
    setCookie(BEARER_TOKEN, rs.accessToken?.split('Bearer ')[1]);
    setCookie(REFRESH_TOKEN, rs.refreshToken);
}

export const removeTokens = () => {
    deleteCookie(BEARER_TOKEN);
    deleteCookie(REFRESH_TOKEN);
}

export const updateTokens = (rs: TUserSign) => {
    removeTokens();
    setTokens(rs);
}

export const getBearerToken = () => getCookie(BEARER_TOKEN);
export const getRefreshToken = () => getCookie(REFRESH_TOKEN);

const setCookie = (name: string, value: string, props: { [key: string]: string | number | Date | boolean } = {}) => {

    let exp = props.expires;
    if (typeof exp == 'number' && exp) {
        const d = new Date();
        d.setTime(d.getTime() + exp * 1000);
        exp = props.expires = d;
    }
    if (exp && exp instanceof Date) {
        props.expires = exp.toUTCString();
    }
    value = encodeURIComponent(value);
    let updatedCookie = name + '=' + value;
    for (const propName in props) {
        updatedCookie += '; ' + propName;
        const propValue = props[propName];
        if (propValue !== true) {
            updatedCookie += '=' + propValue;
        }
    }
    document.cookie = updatedCookie;
}

const getCookie = (name: string) => {
    const matches = document.cookie.match(
        new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)') // eslint-disable-line
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

const deleteCookie = (name: string) => setCookie(name, "", {expires: -1})