import {getBearerToken, getRefreshToken, updateTokens} from "../utils/token-handler";
import {TInfoMessage} from "../store/user-auth/actions";
import {TIngredientsRs} from "../types/t-ingredients-rs";
import {TUserData} from "../types/t-user-data";
import {TCreateOrderRs} from "../types/t-create-order-rs";
import {TUserSign} from "../types/t-user-sign";

export const URL_BURGER = 'https://norma.nomoreparties.space/';
export const INGREDIENTS_ENDPOINT = 'api/ingredients';
export const ORDERS_ENDPOINT = 'api/orders';
const LOGIN = 'api/auth/login';
const REGISTER = 'api/auth/register';
const LOGOUT = 'api/auth/logout';
const RESET_PASSWORD_1_STEP = 'api/password-reset';
const RESET_PASSWORD_2_STEP = 'api/password-reset/reset';

export const TOKEN_ENDPOINT = 'api/auth/token';
export const USER_ENDPOINT = 'api/auth/user';

const JWT_EXPIRED = 'jwt expired';

export const fetchIngredientsRq: () => Promise<TIngredientsRs> = async () => sendRq(INGREDIENTS_ENDPOINT);

export const createOrderRq: (orderList: string[]) => Promise<TCreateOrderRs> = async (orderList) => sendRqWithTokenUpdate(
    ORDERS_ENDPOINT,
    {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${getBearerToken()}`
        },
        body: JSON.stringify({"ingredients": orderList})
    }
);

export const loginRq: (userData: TUserData) => Promise<TUserSign> = (userData) => sendRq(
    LOGIN,
    {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(userData)
    }
);

export const registerRq: (userData: TUserData) => Promise<TUserSign> = (userData) => sendRq(
    REGISTER,
    {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(userData)
    }
);

export const logoutRq: () => Promise<TInfoMessage> = () => sendRq(
    LOGOUT,
    {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({token: getRefreshToken()})
    }
);

export const resetPasswordRq: (email: string) => Promise<TInfoMessage> = (email) => sendRq(
    RESET_PASSWORD_1_STEP,
    {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({email})
    }
)

export const resetPasswordSendMessageFromEmailRq: ({password, token}: { password: string, token: string })
    => Promise<TInfoMessage>
    = ({password, token}) => sendRq(
    RESET_PASSWORD_2_STEP,
    {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({password, token})
    }
)

export const updateUserDataRq: (userData: TUserData) => Promise<TUserSign> = (userData: TUserData) => sendRqWithTokenUpdate(
    USER_ENDPOINT,
    {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${getBearerToken()}`
        },
        body: JSON.stringify(userData)
    }
)


export const fetchUserDataRq: () => Promise<TUserSign> = () => sendRqWithTokenUpdate(
    USER_ENDPOINT,
    {
        headers: {'authorization': `Bearer ${getBearerToken()}`},
    }
);


const sendRqWithTokenUpdate = async <T>(url: RequestInfo, options: RequestInit): Promise<T> => {
    try {
        return await sendRq(url, options);

    } catch (err: any) {
        return err.reject
            ? err?.reject.then((body: { message: string; }) => {
                    return JWT_EXPIRED === body.message
                        ? updateToken()
                            .then((rs: TUserSign) => {
                                !rs.success && Promise.reject(rs);

                                updateTokens(rs);

                                (options.headers as { [key: string]: string }).authorization = rs.accessToken;
                                return sendRq(url, options);
                            })
                        : Promise.reject(err)
                }
            ) : Promise.reject(err)
    }
};


const updateToken = (): Promise<TUserSign> => sendRq(
    TOKEN_ENDPOINT,
    {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({token: getRefreshToken()})
    });

const sendRq = <T>(endpoint: RequestInfo, options: RequestInit = {}): Promise<T> => {
    return fetch(URL_BURGER + endpoint, options)
        .then(rs => {
            if (rs.ok) return rs.json();
            return Promise.reject({reject: rs.json(), status: rs.status});
        })
}