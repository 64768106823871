import {CLOSE_ORDER_MODAL, CREATE_ORDER_FAILED, CREATE_ORDER_PENDING, CREATE_ORDER_SUCCESS} from "./const";
import {TOrderActions} from "./actions";


type TOrderState = {
    number: string,
    isModalOpen: boolean,
    isPending: boolean,
    isError: boolean,
    // error: { status: string, body: object, message: string } | null
    error: { message?: string} | null
}
export const initialState: TOrderState = {
    number: '',
    isModalOpen: false,
    isPending: false,
    isError: false,
    error: null
};


export const reducer = (state = initialState, action: TOrderActions): TOrderState => {
    switch (action.type) {

        case CREATE_ORDER_PENDING:
            return {
                ...state,
                isPending: true,
                isError: false,
                error: null,
                isModalOpen: false
            }

        case CREATE_ORDER_SUCCESS: {
            return {
                ...state,
                number: action.payload.order.number,
                isModalOpen: true,
                isPending: false,
                isError: false,
                error: null

            };
        }
        case CREATE_ORDER_FAILED: {

            return {
                ...state,
                isError: true,
                error: action.payload,
                isPending: false
            };
        }
        case CLOSE_ORDER_MODAL: {
            return {
                ...state,
                isModalOpen: false
            };
        }
        default: {
            return state;
        }
    }
};