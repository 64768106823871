import {Tabs} from './tabs/tabs';
import s from './burger-ingredients.module.scss';
import {BurgerItem} from './burger-item/burger-item';

import scroll from '../../common-styles/scroll.module.scss';
import {FC, useEffect, useState} from "react";
import {getIngredients, ingredientsSelector} from "../../store/all-ingredients/selectors";
import {useInView} from "react-intersection-observer";
import {getBunsInConstructor, getMainAndSauceInConstructor} from "../../store/ingredients-in-constructor/selectors";
import {Preloader} from "../preloader/preloader";
import {TPossibleRefsKeys, types} from "../../types/types";
import {TIngredient} from "../../types/t-ingredient";
import {useSelector} from "../../store/hooks.";


export const BurgerIngredients: FC = () => {
    const [bunRef, inViewBuns] = useInView({threshold: 0});
    const [mainRef, inViewMain] = useInView({threshold: 0});
    const [sauceRef, inViewSauces] = useInView({threshold: 0});

    const refs = {
        'bun': bunRef,
        'main': mainRef,
        'sauce': sauceRef,
    }

    const [activeTab, setActiveTab] = useState('bun');

    const data: Array<TIngredient> = useSelector(getIngredients);
    const {isError, isPending} = useSelector(ingredientsSelector);

    const selectedIngredients = [...useSelector(getBunsInConstructor), ...useSelector(getMainAndSauceInConstructor)].map(el => el._id);

    const availableTypes: Array<TPossibleRefsKeys> = [...new Set(data.map((el: TIngredient) => el.type))];


    useEffect(() => {
        if (inViewBuns) {
            setActiveTab("bun");
        } else if (inViewSauces) {
            setActiveTab("sauce");
        } else if (inViewMain) {
            setActiveTab("main");
        }
    }, [inViewBuns, inViewMain, inViewSauces]);


    return (
        <section id="scroll" className={`${s.wrapper} mt-8`}>
            <h1 className='mb-5 text text_type_main-large'>Соберите бургер</h1>
            {
                isPending ? <Preloader/> :
                    isError ? <h3 className={`text_type_main-large`}>Не удалось загрузить ингридиенты</h3>
                        : <>
                            <Tabs activeTab={activeTab} setActiveTab={setActiveTab}/>
                            <div className={`${s.ingredients} ${scroll.scroll} mt-2`}>
                                {
                                    availableTypes.map((type: TPossibleRefsKeys) => (
                                        <div key={type} className='mb-2'
                                             ref={refs[type]}
                                             id={type}
                                        >
                                            <p className='mt-8 text_type_main-medium'>
                                                {types.find((el: { value: string }) => el.value === type)?.text}
                                            </p>

                                            <div className={`${s.cards} mt-6`}>

                                                {data.map((item: TIngredient) => (
                                                    item.type === type &&
                                                    <BurgerItem
                                                        count={selectedIngredients.filter(id => id === item._id).length}
                                                        item={item}
                                                        key={item._id}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </>
            }

        </section>
    );
};

