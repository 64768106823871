import {TIngredient} from "../types/t-ingredient";
import {format, formatDistance, isToday, isYesterday} from "date-fns";
import {ru} from "date-fns/locale";

export const calcSum = (ids: string [], ingredients: TIngredient[]) => ids
    .map(id => ingredients.find(el => el._id === id)?.price)
    .reduce((a, b) => (a && b && (a + b)));


export const calcTime = (createdAt: string ) => {
    const date = new Date(createdAt);
    const dateAndTime = format(date, "kk:mm");

    return isToday(date) ?
        `Сегодня, ${dateAndTime}`
        : isYesterday(date) ?
            `Вчера, ${dateAndTime}`
            : `${formatDistance(date, Date.now(), {locale: ru})} назад`
}