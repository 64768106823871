import React, {FC} from 'react';
import {CurrencyIcon} from '@ya.praktikum/react-developer-burger-ui-components';
import s from './order-card.module.scss';

import {Link, Outlet, useLocation} from "react-router-dom";
import {getIngredients} from "../../../store/all-ingredients/selectors";
import {OrderStatus} from "../order-status/order-status";
import {TWsOrder} from "../../../types/t-ws-order";
import {calcSum, calcTime} from "../../../utils/calc-values-for-order-feed";
import {useSelector} from "../../../store/hooks.";


export const OrderCard: FC<{ order: TWsOrder, link: string }> = ({order, link}) => {
    const location = useLocation();
    const ingredients = useSelector(getIngredients);
    const calcOrderSum = () => calcSum(order.ingredients, ingredients);
    const calcOrderTime = () => calcTime(order.createdAt);

    return (
        <>
            <Link
                className={s.link}
                to={`${link}${order.number}`}
                state={{backgroundLocation: location}}
            >

                <div className={`${s.card_wrapper} mb-4 mr-2`}>
                    <div className={`${s.info_wrapper} mb-6`}>
                        <p className="text text_type_digits-default">{`# ${order.number}`}</p>
                        <p className="text text_type_main-default text_color_inactive">{calcOrderTime()}</p>
                    </div>

                    <h2 className="text text_type_main-medium mb-4">{order.name}</h2>
                    {location.pathname === "/profile/orders" && <OrderStatus status={order.status}/>}

                    <div className={s.info_wrapper}>
                        <div className={s.ingredients_wrapper}>
                            {
                                order.ingredients.map((ingredientId: string, index: number) => (
                                    index < 5 ?
                                        <div key={index} className={s.round}>
                                            {<img src={ingredients.find(el => el._id === ingredientId)?.image_mobile}
                                                  alt="ingredient"/>}
                                        </div>
                                        : index === 6 ?
                                            <div key={index} className={`${s.round} ${s.round_add}`}>
                                                <img className={`${s.ingredients_opacity}`}
                                                     src={ingredients.find(el => el._id === ingredientId)?.image_mobile}
                                                     alt="ingredient"/>
                                                <div className={`text text_type_digits-default ${s.round_add2}`}>
                                                    {`+${order.ingredients.length - 5}`}</div>
                                            </div>
                                            : null
                                ))
                            }
                        </div>

                        <div className={`${s.wrapper}`}>
                            <span className="text text_type_digits-default mr-2">{calcOrderSum()}</span>
                            <CurrencyIcon type="primary"/>
                        </div>
                    </div>

                </div>
            </Link>
            <Outlet />
        </>

    )
}
