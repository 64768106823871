import {combineReducers} from "redux";
import {reducer as ingredientsReducer} from "./all-ingredients/reducer";
import {reducer as constructorReducer} from "./ingredients-in-constructor/reducer";
import {reducer as orderReducer} from "./create-order/reducer";
import {reducer as authReducer} from "./user-auth/reducer";
import {reducer as allOrdersReducer} from "./all-orders/reducer";
import {reducer as userOrdersReducer} from "./user-orders/reducer";

export const rootReducer = combineReducers({
    ingredients: ingredientsReducer,
    ingredientsInConstructor: constructorReducer,
    order: orderReducer,
    auth: authReducer,
    allOrders: allOrdersReducer,
    userOrders: userOrdersReducer
})