import {
    ADD_BUN_TO_CONSTRUCTOR,
    ADD_INGREDIENT_TO_CONSTRUCTOR,
    DELETE_INGREDIENT_FROM_CONSTRUCTOR,
    REORDER_INGREDIENTS_IN_CONSTRUCTOR,
    RESET_INGREDIENTS_IN_CONSTRUCTOR
} from "./const";
import {AppDispatch, AppThunk} from "../store";
import {TIngredient, TUpdatedIngredient} from "../../types/t-ingredient";


export interface IAddBunToConstructor {
    readonly type: typeof ADD_BUN_TO_CONSTRUCTOR;
    readonly payload: TIngredient
}

export interface IAddMainOrSauceToConstructor {
    readonly type: typeof ADD_INGREDIENT_TO_CONSTRUCTOR;
    readonly payload: TUpdatedIngredient
}

export interface IDeleteMainOrSauceFromConstructor {
    readonly type: typeof DELETE_INGREDIENT_FROM_CONSTRUCTOR;
    readonly payload: TUpdatedIngredient
}

export interface IReorderMainAndSauceInConstructor {
    readonly type: typeof REORDER_INGREDIENTS_IN_CONSTRUCTOR;
    readonly payload: { from: number, to: number }
}

export interface IResetConstructor {
    readonly type: typeof RESET_INGREDIENTS_IN_CONSTRUCTOR;
}

export type TIngredientsInConstructorActions =
    | IAddBunToConstructor
    | IAddMainOrSauceToConstructor
    | IDeleteMainOrSauceFromConstructor
    | IReorderMainAndSauceInConstructor
    | IResetConstructor


export const addBunToConstructor: (item: TIngredient) => AppThunk = (item) => (dispatch: AppDispatch) => {
    dispatch({
        type: ADD_BUN_TO_CONSTRUCTOR,
        payload: item
    })
}

export const addMainOrSauceToConstructor: (item: TIngredient) => AppThunk = (item) => (dispatch: AppDispatch) => {
    dispatch({
        type: ADD_INGREDIENT_TO_CONSTRUCTOR,
        payload: {...item, uiId: crypto.randomUUID()}
    })
}

export const deleteMainOrSauceFromConstructor: (item: TUpdatedIngredient) => AppThunk = (item) => (dispatch: AppDispatch) => {
    dispatch({
        type: DELETE_INGREDIENT_FROM_CONSTRUCTOR,
        payload: item
    })
}

export const reorderMainAndSauceInConstructor: (dragIndex: number, hoverIndex: number) => AppThunk = (dragIndex, hoverIndex) => (dispatch: AppDispatch) => {
    dispatch({
        type: REORDER_INGREDIENTS_IN_CONSTRUCTOR,
        payload: {
            from: dragIndex,
            to: hoverIndex,
        }
    })
}

export const resetConstructor: () => AppThunk = () => (dispatch: AppDispatch) => {
    dispatch({
        type: RESET_INGREDIENTS_IN_CONSTRUCTOR,
    })
}