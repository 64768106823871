import {GET_ALL_INGREDIENTS_ERROR, GET_ALL_INGREDIENTS_PENDING, GET_ALL_INGREDIENTS_SUCCESS} from "./const";
import {TGetIngredientActions} from "./actions";
import {TIngredient} from "../../types/t-ingredient";

type TIngredientsState = {
    ingredients: TIngredient[],
    isError: boolean,
    isPending: boolean,
    error: any | null
}

export const initialState: TIngredientsState = {
    ingredients: [],
    isError: false,
    isPending: false,
    error: null
}

export const reducer = (state: TIngredientsState = initialState, action : TGetIngredientActions): TIngredientsState => {
    switch (action.type) {

        case GET_ALL_INGREDIENTS_PENDING:
            return {
                ...state,
                isError: false,
                isPending: true
            }

        case GET_ALL_INGREDIENTS_SUCCESS:
            return {
                ...state,
                ingredients: action.payload.data,
                isError: false,
                isPending: false
            }

        case GET_ALL_INGREDIENTS_ERROR:
            return {
                ...state,
                isError: true,
                isPending: false,
                error: action.payload
            }

        default:
            return state;
    }
}