export type TPossibleRefsKeys = 'bun' | 'main' | 'sauce';
export const types: Array<{value: TPossibleRefsKeys, text: string}> = [
    {
        value: 'bun',
        text: 'Булки',
    },
    {
        value: 'main',
        text: 'Начинки',
    },
    {
        value: 'sauce',
        text: 'Соусы',
    },
]

