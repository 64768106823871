import {FC} from "react";
import {totalSelector, totalTodaySelector} from "../../../store/all-orders/selectors";
import s from './orders-info.module.scss';
import {TWsOrder} from "../../../types/t-ws-order";
import {useSelector} from "../../../store/hooks.";

export const OrdersInfo: FC<{ orders: TWsOrder[] }> = ({orders}) => {

    const total = useSelector(totalSelector);
    const totalToday = useSelector(totalTodaySelector);

    const doneOrders = orders
        .filter(order => order.status === 'done')
        .slice(0, 20)
        .map(order => order.number);

    const createdOrders = orders
        .filter(order => ['created', 'pending'].includes(order.status))
        .slice(0, 20)
        .map(order => order.number);

    return (
        <div>
            <div style={{display:'flex'}}>
                <div className="mr-15">
                    <p className='text text_type_main-medium mb-6'>Готовы:</p>

                    <div style={{display:'flex'}}>
                        <ul className='mr-10'>
                            {doneOrders.slice(0,10).map(number => (
                                <li className={`${s.list_of_done} text text_type_digits-default`} key={number}>{number}</li>
                            ))}
                        </ul>
                        <ul>
                            {doneOrders.slice(10,20).map(number => (
                                <li className={`${s.list_of_done} text text_type_digits-default`} key={number}>{number}</li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div>
                    <p className='text text_type_main-medium mb-6'>В работе:</p>
                    <div style={{display:'flex'}}>
                        <ul className='mr-10'>
                            {createdOrders.slice(0,10).map(number => (
                                <li className={`${s.list_of_created} text text_type_digits-default`} key={number}>{number}</li>
                            ))}
                        </ul>
                        <ul>
                            {createdOrders.slice(10,20).map(number => (
                                <li className={`${s.list_of_created} text text_type_digits-default`} key={number}>{number}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            <p className='text text_type_main-medium mt-10'>Выполнено за все время:</p>
            <span className={`text text_type_digits-large`}>{total}</span>

            <p className='text text_type_main-medium mt-10 '>Выполнено за сегодня:</p>
            <span className={`text text_type_digits-large`}>{totalToday}</span>
        </div>

    )
}