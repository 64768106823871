import React, {FC, FormEvent} from 'react';
import s from '../../common-styles/form.module.scss';
import {Button, EmailInput, PasswordInput} from '@ya.praktikum/react-developer-burger-ui-components';
import {Link} from 'react-router-dom';
import {loginAction} from "../../store/user-auth/actions";
import {useForm} from "../../hooks/use-form";
import {useDispatch} from "../../store/hooks.";

export const LoginPage: FC = () => {
    const dispatch = useDispatch();

    const {values, handleChange} = useForm({
        email: '',
        password: ''
    });

    const submitLogin = (e: FormEvent) => {
        e.preventDefault();
        dispatch(loginAction({email: values.email, password: values.password}))
    }


    return <div className={s.wrapper}>
        <form className={`${s.form}`} action='' onSubmit={e => submitLogin(e)}>

            <h3 className='text text_type_main-medium mb-6'>Вход</h3>

            <EmailInput
                data-test='email-input'
                name={'email'}
                extraClass='mb-6'
                onChange={handleChange}
                value={values.email}
            />

            <PasswordInput
                data-test='password-input'
                name={'password'}
                extraClass='mb-6'
                onChange={handleChange}
                value={values.password}
            />

            <Button
                data-test='submit-login-button'
                extraClass='mb-20'
                htmlType='submit'
                type='primary'
                size='large'>
                Войти
            </Button>

            <div className={`${s.link_wrapper} mb-4 text text_type_main-default`}>
                <p className={s.text}>Вы - новый пользователь?</p>
                <Link className={`${s.link} ml-2`} to='/register'>Зарегистрироваться</Link>

            </div>

            <div className={`${s.link_wrapper} text text_type_main-default`}>
                <p className={s.text}>Забыли пароль?</p>
                <Link className={`${s.link} ml-2`} to='/forgot-password'>Восстановить пароль</Link>
            </div>

        </form>
    </div>

}