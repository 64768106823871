import React, {FC} from "react";
import s from '../../common-styles/form.module.scss';
import {NavLink, Outlet} from "react-router-dom";
import {logoutAction} from "../../store/user-auth/actions";
import {useDispatch} from "../../store/hooks.";

export const Profile: FC = () => {
    const dispatch = useDispatch();
    const handleLogout = () => dispatch(logoutAction());


    return (
        <div className={`${s.wrapper}`}>
            <div className={`${s.main} mt-30`}>
                <div className={`${s.sidebar} mr-15`}>

                    <NavLink end
                        to=''
                        className={({isActive}) =>
                        isActive
                            ? `${s.selected_link} text text_type_main-medium mt-6 mb-6`
                            : `${s.inactive_link} text text_type_main-medium mt-6 mb-6`
                    }>
                        Профиль
                    </NavLink>

                    <NavLink
                        to='orders'
                        className={({isActive}) =>
                            isActive
                                ? `${s.selected_link} text text_type_main-medium mt-6 mb-6`
                                : `${s.inactive_link} text text_type_main-medium mt-6 mb-6`
                        }>
                        История заказов
                    </NavLink>

                    <NavLink
                        to='/'
                        onClick={handleLogout}
                        className={`${s.inactive_link} text text_type_main-medium mt-6 mb-6`}>
                        Выход
                    </NavLink>

                    <p className={`${s.subtext} text text_type_main-default mt-20`}>
                        В этом разделе вы можете <br/>изменить свои персональные данные
                    </p>
                </div>
                <Outlet/>

            </div>
        </div>
    )
}



