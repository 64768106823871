export const REGISTER_USER_PENDING: 'REGISTER_USER_PENDING' = 'REGISTER_USER_PENDING';
export const REGISTER_USER_SUCCESS: 'REGISTER_USER_SUCCESS' = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR: 'REGISTER_USER_ERROR' = 'REGISTER_USER_ERROR';


export const LOGIN_USER_PENDING: 'LOGIN_USER_PENDING' = 'LOGIN_USER_PENDING';
export const LOGIN_USER_SUCCESS: 'LOGIN_USER_SUCCESS' = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR: 'LOGIN_USER_ERROR' = 'LOGIN_USER_ERROR';


export const GET_USER_INFO_PENDING: 'GET_USER_INFO_PENDING' = 'GET_USER_INFO_PENDING';
export const GET_USER_INFO_SUCCESS: 'GET_USER_INFO_SUCCESS' = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_ERROR: 'GET_USER_INFO_ERROR' = 'GET_USER_INFO_ERROR';


export const GET_NEW_TOKEN_PENDING: 'GET_NEW_TOKEN_PENDING' = 'GET_NEW_TOKEN_PENDING';
export const GET_NEW_TOKEN_SUCCESS: 'GET_NEW_TOKEN_SUCCESS' = 'GET_NEW_TOKEN_SUCCESS';
export const GET_NEW_TOKEN_ERROR: 'GET_NEW_TOKEN_ERROR' = 'GET_NEW_TOKEN_ERROR';


export const LOGOUT_PENDING: 'LOGOUT_PENDING' = 'LOGOUT_PENDING';
export const LOGOUT_SUCCESS:  'LOGOUT_SUCCESS' = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR: 'LOGOUT_ERROR' = 'LOGOUT_ERROR';

export const CLEAR_ERR: 'CLEAR_ERR' = 'CLEAR_ERR';


export const RESET_PASSWORD_STEP_1_PENDING: 'RESET_PASSWORD_STEP_1_PENDING' = 'RESET_PASSWORD_STEP_1_PENDING';
export const RESET_PASSWORD_STEP_1_SUCCESS: 'RESET_PASSWORD_STEP_1_SUCCESS' = 'RESET_PASSWORD_STEP_1_SUCCESS';
export const RESET_PASSWORD_STEP_1_ERROR: 'RESET_PASSWORD_STEP_1_ERROR' = 'RESET_PASSWORD_STEP_1_ERROR';


export const RESET_PASSWORD_STEP_2_PENDING: 'RESET_PASSWORD_STEP_2_PENDING' = 'RESET_PASSWORD_STEP_2_PENDING';
export const RESET_PASSWORD_STEP_2_SUCCESS: 'RESET_PASSWORD_STEP_2_SUCCESS' = 'RESET_PASSWORD_STEP_2_SUCCESS';
export const RESET_PASSWORD_STEP_2_ERROR: 'RESET_PASSWORD_STEP_2_ERROR' = 'RESET_PASSWORD_STEP_2_ERROR';


export const UPDATE_USER_INFO_PENDING: 'UPDATE_USER_INFO_PENDING' = 'UPDATE_USER_INFO_PENDING';
export const UPDATE_USER_INFO_SUCCESS: 'UPDATE_USER_INFO_SUCCESS' = 'UPDATE_USER_INFO_SUCCESS';
export const UPDATE_USER_INFO_ERROR: 'UPDATE_USER_ERROR' = 'UPDATE_USER_ERROR';