import {createOrderRq} from "../../client/api-client";
import {CLOSE_ORDER_MODAL, CREATE_ORDER_FAILED, CREATE_ORDER_PENDING, CREATE_ORDER_SUCCESS} from "./const";
import {AppDispatch, AppThunk} from "../store";
import {TInfoMessage} from "../user-auth/actions";
import {TCreateOrderRs} from "../../types/t-create-order-rs";


export interface ICreateOrderPending {
    readonly type: typeof CREATE_ORDER_PENDING;
}

export interface ICreateOrderSuccess {
    readonly type: typeof CREATE_ORDER_SUCCESS;
    readonly payload: TCreateOrderRs

}

export interface ICreateOrderFailed {
    readonly type: typeof CREATE_ORDER_FAILED;
    readonly payload: TInfoMessage
}

export interface ICLoseModal {
    readonly type: typeof CLOSE_ORDER_MODAL;
}

export type TOrderActions =
    | ICreateOrderPending
    | ICreateOrderSuccess
    | ICreateOrderFailed
    | ICLoseModal


export const createOrderAction: (orderList: string []) => AppThunk = (orderList) => (dispatch: AppDispatch) => {
    dispatch({
        type: CREATE_ORDER_PENDING
    })
    createOrderRq(orderList)
        .then(rs => {
            dispatch({
                type: CREATE_ORDER_SUCCESS,
                payload: rs
            })
        })
        .catch(err => {
            dispatch({
                type: CREATE_ORDER_FAILED,
                payload: err
            })
            alert("Не удалось создать заказ");
        })
}


export const closeOrderModal: () => AppThunk = () => (dispatch: AppDispatch) => {
    dispatch({
        type: CLOSE_ORDER_MODAL,
    })
}


