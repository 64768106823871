import {Counter, CurrencyIcon} from '@ya.praktikum/react-developer-burger-ui-components';
import s from './burger-item.module.scss';
import {useDrag} from "react-dnd";
import {Link, Outlet, useLocation} from "react-router-dom";
import {FC} from "react";
import {TIngredient} from "../../../types/t-ingredient";


type TBurgerItem = {
    item: TIngredient,
    count: number
}
export const BurgerItem: FC<TBurgerItem> = ({item, count}) => {
    const location = useLocation();

    const [{isDrag, canDrag}, ref] = useDrag({
        type: "ingredient",
        item: item,

        collect: monitor => ({
            isDrag: monitor.isDragging(),
            canDrag: monitor.canDrag()
        })
    });

    const opacity = isDrag ? 0.1 : 1


    return (
        <>
            <Link
                className={`${s.item} mt-6 mb-2`}
                to={`/ingredients/${item._id}`}
                state={{backgroundLocation: location}}
            >
                <div ref={ref} style={{opacity}}>
                    <img src={item.image} alt={item.name}/>

                    {
                        canDrag && count !== 0 && <Counter count={count} size="default"/>
                    }

                    <div className={`${s.price} mt-1 mb-1`}>
                        <p className="mr-2 text text_type_digits-default">{item.price}</p>
                        <CurrencyIcon type="primary"/>
                    </div>
                    <p className="mt-1 text text_type_main-default">{item.name}</p>
                </div>
            </Link>
            <Outlet />
        </>


    )
}


