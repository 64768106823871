import {applyMiddleware, compose, createStore} from "redux";
import {rootReducer} from "./reducer";
import thunk, {ThunkAction, ThunkDispatch} from "redux-thunk";
import {TGetIngredientActions} from "./all-ingredients/actions";
import {TOrderActions} from "./create-order/actions";
import {TIngredientsInConstructorActions} from "./ingredients-in-constructor/actions";
import {TAuthActions} from "./user-auth/actions";
import {TUSerOrdersActions} from "./user-orders/actions";
import {TAllOrdersActions} from "./all-orders/actions";
import {wsMiddleware} from "./ws-middleware";
import {
    ALL_ORDERS_CLOSE, ALL_ORDERS_ON_CLOSE, ALL_ORDERS_ON_ERROR,
    ALL_ORDERS_INIT,
    ALL_ORDERS_ON_OPEN, ALL_ORDERS_ON_MESSAGE
} from "./all-orders/const";
import {
    USER_ORDERS_CLOSE,
    USER_ORDERS_INIT,
    USER_ORDERS_ON_CLOSE,
    USER_ORDERS_ON_ERROR, USER_ORDERS_ON_MESSAGE,
    USER_ORDERS_ON_OPEN
} from "./user-orders/const";


export const composeEnhancers =
    typeof window === 'object' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose;

export const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(
            thunk,
            wsMiddleware({
                init: ALL_ORDERS_INIT,
                close: ALL_ORDERS_CLOSE,
                onOpen: ALL_ORDERS_ON_OPEN,
                onClose: ALL_ORDERS_ON_CLOSE,
                onError: ALL_ORDERS_ON_ERROR,
                onMessage: ALL_ORDERS_ON_MESSAGE
            }),
            wsMiddleware({
                init: USER_ORDERS_INIT,
                close: USER_ORDERS_CLOSE,
                onOpen: USER_ORDERS_ON_OPEN,
                onClose: USER_ORDERS_ON_CLOSE,
                onError: USER_ORDERS_ON_ERROR,
                onMessage: USER_ORDERS_ON_MESSAGE
            }),

        ))
)

export type TApplicationActions =
    | TGetIngredientActions
    | TOrderActions
    | TIngredientsInConstructorActions
    | TAuthActions
    | TUSerOrdersActions
    | TAllOrdersActions


/*
    https://redux.js.org/usage/usage-with-typescript
 */
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = ThunkDispatch<RootState, any, TApplicationActions>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    TApplicationActions
>








