import {Middleware} from "redux";
import {RootState} from "./store";


export type TWsActions = {
    init: string;
    close: string;

    onOpen: string;
    onClose: string;
    onError: string;
    onMessage: string;
};
export const wsMiddleware = (actions: TWsActions): Middleware<{}, RootState> => {
    return (store) => {

        let socket: WebSocket;
        const {init, close, onOpen, onClose, onError, onMessage} = actions;

        return next => action => {
            const {dispatch} = store;
            const {type, payload} = action;

            if (type === init) {
                socket = new WebSocket(payload);

                socket.onopen = event => dispatch({type: onOpen, payload: event});
                socket.onclose = event => dispatch({type: onClose, payload: event});
                socket.onerror = event => dispatch({type: onError, payload: event});

                socket.onmessage = event => {
                    dispatch({
                        type: onMessage,
                        payload: {
                            data: JSON.parse(event.data),
                            createdAt: new Date().getTime() / 100,
                        },
                    });
                };

            }

            if (socket && (type === close) && (socket.readyState === 1)) socket.close();

            next(action);
        };
    };
};