import {useLocation, useParams} from "react-router-dom";
import {userOrdersSelector} from "../../../store/user-orders/selectors";
import {allOrdersSelector} from "../../../store/all-orders/selectors";
import React, {useEffect} from "react";
import {closeAllOrdersConnection, initAllOrdersConnection} from "../../../store/all-orders/actions";
import {closeUserOrdersConnection, initUserOrdersConnection} from "../../../store/user-orders/actions";
import s from "./feed-order-details.module.scss";
import {OrderStatus} from "../order-status/order-status";
import {CurrencyIcon} from "@ya.praktikum/react-developer-burger-ui-components";
import {getIngredients} from "../../../store/all-ingredients/selectors";
import scroll from '../../../common-styles/scroll.module.scss';
import {OrderRow} from "../order-row/order-row";
import {SmallPreloader} from "../../preloader/preloader";
import {TWsOrder} from "../../../types/t-ws-order";
import {calcSum, calcTime} from "../../../utils/calc-values-for-order-feed";
import {useDispatch, useSelector} from "../../../store/hooks.";

export const FeedOrderDetails = () => {
    const location = useLocation();
    const isOpenedByClick = location.state?.backgroundLocationOrder !== undefined;
    const isUserOrderModal = location.pathname.includes('profile');

    const ingredients = useSelector(getIngredients);

    const {id} = useParams();
    const dispatch = useDispatch();

    const allOrders: TWsOrder[] = useSelector(allOrdersSelector);
    const userOrders: TWsOrder[] = useSelector(userOrdersSelector);

    useEffect(() => {
        if (!isOpenedByClick) {
            isUserOrderModal
                ? dispatch(initUserOrdersConnection())
                : dispatch(initAllOrdersConnection());

            return () => {
                isUserOrderModal
                    ? dispatch(closeUserOrdersConnection())
                    : dispatch(closeAllOrdersConnection());
            }
        }

    }, [dispatch]);

    const calcOrderSum = () => order && order.ingredients.length !== 0 && calcSum(order.ingredients, ingredients);
    const calcOrderTime = () => order && calcTime(order?.createdAt);

    const order = isUserOrderModal
        ? userOrders.find(el => el.number === Number(id))
        : allOrders.find(el => el.number === Number(id));

    const ingredientWithNum = order?.ingredients.reduce<Record<string, number>>((prev, item) => {
        !prev[item] ? prev[item] = 1 : prev[item] += 1;
        return prev;
    }, {});


    return order?.ingredients.length === 0 ? <SmallPreloader/> : (
        <div className={`mb-4 mr-2`}>
            <div className={`mb-2`}>
                <p className="text text_type_digits-default">{`# ${order?.number}`}</p>
            </div>

            <h2 className={`${s.header} text text_type_main-medium mb-2`}>{order?.name}</h2>
            <OrderStatus status={order?.status}/>

            <p className="text text_type_main-medium mb-5 mt-5">{'Состав:'}</p>

            <div className={`${scroll.scroll} ${s.wrapper}`}>
                {
                    [...new Set(order?.ingredients)].map(id => {
                        return <OrderRow
                            key={id}
                            ingredient={ingredients.find(el => el._id === id)}
                            number={ingredientWithNum && ingredientWithNum[id]}
                        />;
                    })
                }

            </div>
            <div className={`${s.footer} mt-10`}>
                <p className="text text_type_main-default text_color_inactive">{calcOrderTime()}</p>
                <div>
                    <span className="text text_type_digits-default mr-2">{calcOrderSum()}</span>
                    <CurrencyIcon type="primary"/>
                </div>

            </div>
        </div>
    )
}