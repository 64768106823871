import {Navigate, useLocation} from "react-router-dom";
import {errorSelector, pendingSelector, userSelector} from "../store/user-auth/selectors";
import {Preloader} from "../components/preloader/preloader";
import {ErrorModal} from "./error-modal/error-modal";
import {FC, ReactNode} from "react";
import {useSelector} from "../store/hooks.";


type TProtected = {
    children: ReactNode
    onlyUnAuth?: boolean
}


export const Protected: FC<TProtected> = ({children, onlyUnAuth = false}) => {
    const isPending = useSelector(pendingSelector);
    const user = useSelector(userSelector);
    const error = useSelector(errorSelector);
    const location = useLocation();


    if (isPending) return <Preloader/>
    if (error) return <ErrorModal/>

    if (onlyUnAuth && user) {
        const {from} = location.state || {from: {pathname: "/"}};
        return <Navigate to={from}/>;
    }

    if (!onlyUnAuth && !user) {
        return (
            <Navigate
                to="/login"
                state={{from: location}}
            />
        );
    }


    return <>{children}</>;


}

