import {ChangeEvent, FC, FormEvent, useEffect, useState} from "react";
import s from '../../common-styles/form.module.scss';
import {Button, EmailInput} from "@ya.praktikum/react-developer-burger-ui-components";
import {Link, useNavigate} from "react-router-dom";
import {shallowEqual} from "react-redux";
import {clearErr, resetPasswordAction} from "../../store/user-auth/actions";
import {userStateSelector} from "../../store/user-auth/selectors";
import {ResetStatus} from "../../types/reset-status";
import {useDispatch, useSelector} from "../../store/hooks.";

export const ForgotPassword: FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {reset} = useSelector(userStateSelector, shallowEqual);

    const [email, setEmail] = useState<string>('');

    const resetPassword = (e: FormEvent) => {
        e.preventDefault();
        dispatch(resetPasswordAction({email}))
    }

    const changeEmailHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        dispatch(clearErr());
    }

    useEffect(() => {
        reset === ResetStatus.RESET && navigate('/reset-password');
    }, [reset])


    return (
        <div className={s.wrapper}>
            <form className={`${s.form}`} onSubmit={resetPassword}>
                <h3 className="text text_type_main-medium mb-6">Восстановление пароля</h3>

                <EmailInput
                    required={true}
                    extraClass='mb-6'
                    placeholder={'Укажите e-mail'}
                    onChange={changeEmailHandler}
                    value={email}
                    name={'name'}
                    size={'default'}
                />

                <Button
                    extraClass='mb-20'
                    htmlType='submit'
                    type="primary"
                    size="large">
                    Восстановить

                </Button>

                <div className={`${s.link_wrapper} mb-4 text text_type_main-default`}>
                    <p className={s.text}>Вспомнили пароль?</p>
                    <Link className={`${s.link} ml-2`} to='/login'>Войти</Link>
                </div>
            </form>
        </div>
    )
}