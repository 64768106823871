import {shallowEqual} from "react-redux";
import {userStateSelector} from "../../../store/user-auth/selectors";
import React, {FC, FormEvent, useMemo, useState} from "react";
import {updateUserDataAction} from "../../../store/user-auth/actions";
import {Button, EmailInput, Input, PasswordInput} from "@ya.praktikum/react-developer-burger-ui-components";
import {useForm} from "../../../hooks/use-form";
import {SmallPreloader} from "../../../components/preloader/preloader";
import {useDispatch, useSelector} from "../../../store/hooks.";

export const UserInfo: FC = () => {
    const dispatch = useDispatch();

    const {isError, isPending, error, user} = useSelector(userStateSelector, shallowEqual);
    const {email, name} = user;

    const {values, handleChange, setValues} = useForm({
        userEmail: email,
        userName: name,
        password: '',
    });

    const [buttonValue, setButtonValue] = useState('Сохранить');

    useMemo(() => {
            if (isPending) setButtonValue('Сохраняем...');
            else if (isError) setButtonValue(error.message);
            else setButtonValue('Сохранить');
        },
        [isPending, isError])

    const updateUSerData = (e: FormEvent) => {
        e.preventDefault();
        dispatch(updateUserDataAction({name: values.userName, email: values.userEmail, password: values.password}))
    }

    const resetUserInput = () => setValues({userEmail: email, userName: name, password: ''})

    const isValueChanged = () => email !== values.userEmail || name !== values.userName || values.password !== '';
    const display = isValueChanged() ? 'inline' : 'none';


    return (
        <form className="mt-1" onSubmit={updateUSerData}>
            <Input
                extraClass='mb-6'
                type='text'
                placeholder='Имя'
                name='userName'
                onChange={handleChange}
                value={values.userName}
                icon='EditIcon'
                errorText='Ошибка'
                size='default'
            />

            <EmailInput
                extraClass='mb-6'
                name='userEmail'
                onChange={handleChange}
                value={values.userEmail}

            />


            <PasswordInput
                extraClass='mb-6'
                name='password'
                onChange={handleChange}
                value={values.password}
            />

            <Button
                style={{display}}
                htmlType='reset'
                type="secondary"
                size="medium"
                onClick={resetUserInput}
            >
                Отмена
            </Button>

            <Button
                disabled={isPending}
                style={{position: 'relative', display}}
                htmlType='submit'
                type="primary"
                size="medium"
            >
                {buttonValue}
                {isPending && <SmallPreloader/>}
            </Button>
        </form>

    )
}